import { createRouter, createWebHistory } from 'vue-router'
import authRoutes from './auth'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/main/index-layout.vue'),
    children: [
      {
        path: '/',
        redirect: { name: 'home' },
        component: () => import('@/views/dashboard/index-page.vue'),
        children: [
          {
            path: '/home',
            name: 'home',
            meta: { title: 'Home' },
            component: () => import('@/views/dashboard/home/index-page.vue'),
          },
          {
            path: '/my-problems',
            name: 'myProblems',
            meta: { title: 'My Problems' },
            // component: () => import('@/views/dashboard/my-problems/index-page.vue'),
            component: () => import('@/views/error/error-503.vue'),
          },
          {
            path: '/notifications',
            name: 'notifications',
            meta: { title: 'Notifications' },
            // component: () => import('@/views/dashboard/notification/index-page.vue'),
            component: () => import('@/views/error/error-503.vue'),
          },
          {
            path: '/labels',
            children: [
              {
                path: '',
                name: 'labels',
                meta: { title: 'Labels' },
                component: () => import('@/views/dashboard/label/index-page.vue'),
              },
              {
                path: ':label',
                name: 'labelView',
                component: () => import('@/views/dashboard/label/view-page.vue'),
              },
              {
                path: ':label/edit',
                name: 'labelUpdate',
                component: () => import('@/views/dashboard/label/update-page.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        meta: { title: 'Maintenance' },
        component: () => import('@/views/error/error-503.vue'),
      },
      {
        path: '/:project_id',
        name: 'project',
        redirect: { name: 'problem' },
        component: () => import('@/views/project/index-page.vue'),
        children: [
          {
            path: 'overview',
            name: 'overview',
            component: () => import('@/views/project/overview/index-page.vue'),
          },
          {
            path: 'timeline',
            name: 'timeline',
            component: () => import('@/views/project/timeline/index-page.vue'),
          },
          {
            path: 'problem',
            children: [
              {
                path: '',
                name: 'problem',
                component: () => import('@/views/project/problem/index-page.vue'),
              },
              {
                path: ':problem_id',
                name: 'problemForm',
                component: () => import('@/views/project/problem/index-page.vue'),
              },
            ],
          },
        ],
      },
      // {
      //   path: '/:pathMatch(.*)*',
      //   component: () => import('@/views/errors/NotFound.vue'),
      // },
    ],
  },
  authRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router